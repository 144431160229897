<template>
  <div class="recycle_bin">
    <nav>
      <div class="operation">

        <span class="user">分类</span>
        <el-select v-model="einvType" placeholder="请选择" class="selectUser" @change="selectEinvType(einvType)"  >
          <el-option v-for="item in typeList" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
        <span class="user">删除日期</span>
        <el-date-picker
            v-model="dateVlaue"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions">
        </el-date-picker>

        <el-button type="primary" @click="searchList" class="query_btn" v-auth='"recycleBin:lookup"'>查询</el-button>
        <span class="warning"><i class="iconfont iconicon-13"></i>回收站只保留最近30天记录哦~</span>
        <el-button type="primary" @click="doEmpty" class="clear_all_btn" v-auth='"recycleBin:clean"'>清空回收站</el-button>
      </div>
    </nav>

    <div class="filter-container">
      <books-table :userId='userId' :einvType='einvType' :dateVlaue='dateVlaue' ref="booksTable" />
    </div>
  </div>
</template>

<script>
import BooksTable from './modules/booksTable'
export default {
  components: { BooksTable },
  data() {
    return {
      queryValue: '',
      userId: this.getToken('userId'),
      userName: this.getToken('userName'),

      typeList: [{'id':'','value':'全部'},{'id':'1','value':'进项发票'},{'id':'2','value':'销项发票'}],  //回收类型
      dateVlaue: [],
      einvType: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }],
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime()
          if (maxDate) {
            this.choiceDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.choiceDate) {
            const day1 =  30 * 24 * 3600 * 1000
            let maxTime = this.choiceDate + day1
            let minTime = this.choiceDate - day1
            return time.getTime() > maxTime || time.getTime()<this.choiceDate || time.getTime() > Date.now()
          }else{
            return time.getTime() > Date.now()+0 * 24 * 3600 * 1000 ||time.getTime() < Date.now()- 30 * 24 * 3600 * 1000
          }

        }
      },
    }
  },
  methods: {
    selectEinvType(einvType) {
      this.einvType = einvType
    },
    searchList() {
      this.$refs.booksTable.getList();
    },
    doEmpty(){
      let _this=this;
      einvAlert.confirm(function(){_this.confirmEmpty()},'您将要永久删除回收站里面的所有发票！','删除','取消')
    },
    confirmEmpty(){
      this.isLoading = true;
      this.$store.dispatch("DoEmpty", {
        companyId: this.getToken("companyId"),
        userId: this.getToken("userId")
      }).then(res => {
        if (res.success) {
          this.$refs.booksTable.getList();
          einvAlert.success("提示","数据清空成功！！")
          this.isLoading = false;
        } else {
          einvAlert.error("提示",res.msg)
          this.isLoading = false;
        }
      }).catch(err => {
        this.isLoading = false
      })
    }

  },
  created() {
  },
}
</script>
<style lang='less'>
 .recycle_bin{
   .operation {
     display: block !important;
   }
 }

</style>
<style lang='less' scoped>
nav {
  height: 60px;
  display: flex;
  padding: 0 18px;
  // line-height: 60px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  .tips {
    font-size: 16px;
    span {
      margin-left: 18px;
    }
    .num {
      color: #4f71ff;
      font-style: normal;
    }
    .btn{
      width: 120px;
      .iconfont{
        font-size: 12px;
        margin-right: 5px;
        margin-left: -5px;
      }
    }
  }
  .operation {
    width: 100%;

    .inputSearch {
      width: 300px;
      margin-right: 20px;
      /deep/ .el-input__inner {
        border-radius: 5px !important;
        height: 35px !important;
        // line-height: 35px;
      }
      /deep/ .el-input__suffix {
        right: 16px;
        top: 10px;
        color: #4f71ff;
      }
    }
    .el-date-editor--daterange{
      width: 400px !important;
    }
    .datePicker{
      width: 400px;
      margin-right: 20px;
    }
    .user{
      line-height: 1;
      font-size: 15px;
      margin-right: 10px;
    }
    .selectUser{
      margin-right: 20px;
    }
    i {
      padding: 10px 8px;
      cursor: pointer;
      color: #FF9700;
    }
    .warning{
      font-size: 16px;
      color: #333333;
    }
    .clear_all_btn{
      float: right;
      position: relative;
      top: 2px;
    }
    .query_btn{
      margin-right: 20px;
    }
  }
}

.filter-container {
  padding: 1.04rem 1.04rem 3.13rem;
  height: calc(100% - 90px);
  background-color: #fff;
}
</style>
